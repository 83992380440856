html,
body {
  margin: 0;
  padding: 0;
  background-color: #eee;
}

:root {
  --gradient: linear-gradient(45deg, #6a65c2, #6c6ce4, #63e5ff);
}

@import url("https://fonts.googleapis.com/css?family=Chewy");

.gradientNav {
  height: 620px;
  background: #ddd;
  background-image: var(--gradient);
  background-size: 400%;
  animation: bg-animation 20s infinite alternate;
}

.navLink {
  margin: 0 10px;
}

.absoluteProfileImage {
  position: absolute;

  transform: scaleX(-1);
  height: 450px;
  width: 495px;
  object-fit: cover;
  border-radius: 5px;
}

.flexProfileImage {
  height: 500px;

  border-radius: 5px;
}

.tesimonialImage {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;

  /* max-height: 250px; */
  /*  scale: 0.8; */
}

.iconStyle {
  height: 77px;
  min-width: 100px;
}

.scrollButtonStyle {
  position: fixed;
  width: 100%;
  left: 90%;
  bottom: 40px;
  height: 20px;
  font-size: 3rem;

  cursor: pointer;
  color: rgb(73, 73, 73);
  transition: opacity 3s ease-in-out;
  opacity: 1;
}

.vippsQr {
  max-height: 350px;
}

@media only screen and (max-width: 479px) {
  .scrollButtonStyle {
    left: 85%;
  }
  .flexProfileImage {
    height: 400px;
  }
  /*   .tesimonialImage {
    width: 180px;
    height: 180px;
  } */
}

@media only screen and (max-width: 400px) {
  .flexProfileImage {
    height: 380px;
  }
  .iconStyle {
    height: 50px;
    min-width: 60px;
  }
  /*   .gradientNav {
    height: 650px;
  } */
  /*   .tesimonialImage {
    width: 180px;
    height: 180px;
  } */
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  100% {
    background-position: right;
  }
}
